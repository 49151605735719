<template>
  <div class="pair-to-help">
    <!-- <van-nav-bar title="党员信息" left-arrow @click-left="onClickLeft" /> -->
    <div class="pair-to-help-body">
      <div class="basic-info">
        <h3>基本信息</h3>
        <div class="img">
          <img :src="detailData.avatar" alt srcset />
        </div>
        <div>
          <p>
            <span>结对党员：</span>
            {{detailData.realName}}
          </p>
          <p>
            <span>所在支部：</span>
            {{detailData.deptName}}
          </p>
          <p>
            <span>出生日前：</span>
            {{detailData.birthday}}
          </p>
          <p>
            <span>联系电话：</span>
            {{detailData.phone}}
          </p>
        </div>
      </div>
      <h2 v-if="false">活动帮扶详情</h2>
      <table v-if="false">
        <tr>
          <th>事项名称</th>
          <th>时间</th>
        </tr>
        <tr>
          <td>发布微心愿</td>
          <td>2020-03-31</td>
        </tr>
        <tr>
          <td>发布任务总结</td>
          <td>2020-03-31 拷贝</td>
        </tr>
        <tr>
          <td>活动签到</td>
          <td>2020-03-31</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { requestDetail } from '@/api/pairToHelp';
export default {
  data() {
    return {
      active: 0,
      activeType: 'all',
      wishList: [],
      detailData: {}
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getDetail() {
      requestDetail({
        id: this.$route.params.id
      }).then(res => {
        // console.log(res);//没有数据
        if (res.success) {
          this.detailData = res.data;
        }
      });
    }
  },
  created() {
    this.getDetail();
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.pair-to-help {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .pair-to-help-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .basic-info {
      margin-top: 20 * $px;
      padding: 25 * $px;
      background: #fff;
      h3 {
        font-size: 32 * $px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 29 * $px;
      }
      .img {
        width: 280 * $px;
        height: 280 * $px;
        background: url('./../../assets/img/avatar.png') center center;
        background-size: cover;
        border-radius: 8 * $px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      & > div {
        font-size: 28 * $px;
        color: #222;
        p {
          margin-top: 23 * $px;
          span {
            color: #555;
          }
        }
      }
    }
    h2 {
      font-size: 32 * $px;
      font-family: PingFang SC;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      margin-top: 20 * $px;
      padding: 20 * $px 0 0 20 * $px;
      background: #fff;
    }
    table {
      background: #fff;
      width: 100%;
      padding: 30 * $px;
      box-sizing: border-box;
      tr {
        font-size: 28 * $px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222;
        margin: 0 20 * $px;
        display: block;
        height: 90 * $px;
        line-height: 90 * $px;
        border-bottom: 2 * $px solid #f3f3f3;
        box-sizing: border-box;
        display: flex;
        th {
          color: #555;
          text-align: left;
          &:nth-of-type(1) {
            width: 350 * $px;
          }
          &:nth-of-type(2) {
            flex: 1;
          }
        }
        td:nth-of-type(1) {
          width: 350 * $px;
        }
      }
    }
  }
}
</style>